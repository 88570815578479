import { useUnleashContext } from '@unleash/proxy-client-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { getRecordingsUrl } from '../components/analytics/HotJar';
import Metrics from '../services/metrics';
import useAccessBadge from './useAccessBadge';
import { NewRelic } from './useNewRelic';

function useIdentifyMetrics(uid: string) {
  const { update: updateIntercom } = useIntercom();
  const router = useRouter();

  const updateContext = useUnleashContext();

  const { accessBadge: userAccessBadge } = useAccessBadge();

  useEffect(() => {
    if (uid) {
      // Identify the user as soon we possible
      Metrics.identifyUser(uid);
    }
  }, [uid]);

  useEffect(() => {
    if (userAccessBadge) {
      const plan = userAccessBadge.license.coarseLevel.toLowerCase();
      const subPlan = userAccessBadge.license.fineLevel.toLowerCase();
      Metrics.setProfileAndCompanyAttributes({
        email: userAccessBadge.user.email,
        name: userAccessBadge.user.firstName
          ? `${userAccessBadge.user.firstName} ${userAccessBadge.user.lastName}`
          : undefined,
        plan,
        subPlan,
        curipodTrialReason: userAccessBadge.license.source,
        country: userAccessBadge.organization?.country || undefined,
        grades: userAccessBadge.user.profileInfo?.grades,
        subjects: userAccessBadge.user.profileInfo?.subjects,
        intendedUses: userAccessBadge.user.profileInfo?.intendedUses,
        schoolRole: userAccessBadge.user.profileInfo?.role,
        signUpOrigin: userAccessBadge.user.profileInfo?.signUpOrigin,
        ssoProvider: userAccessBadge.organization?.ssoProvider,
        // We only connect users to companies when they have become part of an org.
        company:
          userAccessBadge.organization &&
          (userAccessBadge.organization?.source === 'Curipod' ||
            userAccessBadge.organization?.source === 'MCH')
            ? {
                schoolId: userAccessBadge.organization?.id,
                schoolName: userAccessBadge.organization?.name,
                plan: plan,
                subPlan: subPlan,
                curipodTrialReason: userAccessBadge.license.source,
                createdAt: userAccessBadge.organization?.createdAt
                  ? Math.floor(userAccessBadge.organization?.createdAt / 1000)
                  : 0, // intercom api wants it in seconds for some reason...
                createdBy: userAccessBadge.organization?.source,
                districtName: userAccessBadge.organization?.parent?.name,
                city: userAccessBadge.organization?.city,
                region: userAccessBadge.organization?.region,
                state:
                  userAccessBadge.organization?.country === 'US'
                    ? userAccessBadge.organization?.region
                    : undefined,
                zipCode: userAccessBadge.organization?.primaryZipCode,
                country: userAccessBadge.organization?.country,
                mbOrgUsageLink: Metrics.getMetabaseOrgUsageLink(
                  userAccessBadge.organization?.id,
                ),
                mbDistrictUsageLink: Metrics.getMetabaseDistrictOrgUsageLink(
                  userAccessBadge.organization?.parent?.id,
                ),
              }
            : undefined,
        mbUserUsageLink: Metrics.getMetabaseUserUsageLink(userAccessBadge.user.email),
        hotjarRecordings: getRecordingsUrl(userAccessBadge),
      });

      /*
        Intercom only supports strings, booleans, numbers and dates. Data objects must be stringified json. There we can use string contains subject: english.
      */
      updateIntercom({
        userId: userAccessBadge.user.id,
        email: userAccessBadge.user.email,
        name: userAccessBadge.user.firstName
          ? `${userAccessBadge.user.firstName} ${userAccessBadge.user.lastName}`
          : undefined,
        customAttributes: {
          plan,
          subPlan,
          curipodTrialReason: userAccessBadge.license.source,
          userRole: userAccessBadge.role,
          schoolRole: userAccessBadge.user.profileInfo?.role,
          grades: userAccessBadge.user.profileInfo?.grades
            ? JSON.stringify(userAccessBadge.user.profileInfo?.grades)
            : undefined,
          subjects: userAccessBadge.user.profileInfo?.subjects
            ? JSON.stringify(userAccessBadge.user.profileInfo?.subjects)
            : undefined,
          intendedUses: userAccessBadge.user.profileInfo?.intendedUses
            ? JSON.stringify(userAccessBadge.user.profileInfo?.intendedUses)
            : undefined,
          signUpOrigin: userAccessBadge.user.profileInfo?.signUpOrigin,
          accountType: undefined,
          ssoProvider: userAccessBadge.organization?.ssoProvider,
          country:
            userAccessBadge.organization?.country ?? userAccessBadge.tenant?.country,
          schoolName:
            userAccessBadge.organization?.name ?? userAccessBadge.tenant?.tenantName,
          districtId: userAccessBadge.organization?.parent?.id,
          districtName: userAccessBadge.organization?.parent?.name,
          region: userAccessBadge.organization?.region,
          state:
            userAccessBadge.organization?.country === 'US'
              ? userAccessBadge.organization?.region
              : undefined,
          schoolCity: userAccessBadge.organization?.city,
          schoolState: userAccessBadge.organization?.region,
          schoolZipCode: userAccessBadge.organization?.primaryZipCode,
          mbUserUsage: Metrics.getMetabaseUserUsageLink(userAccessBadge.user.email),
          mbOrgUsage: Metrics.getMetabaseOrgUsageLink(userAccessBadge.organization?.id),
          mbDistrictUsage: Metrics.getMetabaseDistrictOrgUsageLink(
            userAccessBadge.organization?.parent?.id,
          ),
          hotjarRecordings: getRecordingsUrl(userAccessBadge),

          mpIndividualUsage: Metrics.getUsageDashboardLink(
            'individual',
            userAccessBadge.user.id,
          ),
          mpDomainUsage: Metrics.getUsageDashboardLink(
            'domain',
            userAccessBadge.user.email.split('@')[1],
          ),
        },
        // We only connect users to companies when they have become part of an org.
        company:
          userAccessBadge.organization &&
          (userAccessBadge.organization?.source === 'Curipod' ||
            userAccessBadge.organization?.source === 'MCH')
            ? {
                companyId: userAccessBadge.organization?.id,
                name: userAccessBadge.organization?.name,
                plan: plan,
                customAttributes: {
                  organisationId: userAccessBadge.organization?.id,
                  subPlan: subPlan,
                  curipodTrialReason: userAccessBadge.license.source,
                  createdAt: userAccessBadge.organization?.createdAt
                    ? Math.floor(userAccessBadge.organization?.createdAt / 1000)
                    : 0, // intercom api wants it in seconds for some reason...
                  createdBy: userAccessBadge.organization?.source,
                  districtName: userAccessBadge.organization?.parent?.name,
                  city: userAccessBadge.organization?.city,
                  region: userAccessBadge.organization?.region,
                  state:
                    userAccessBadge.organization?.country === 'US'
                      ? userAccessBadge.organization?.region
                      : undefined,
                  country: userAccessBadge.organization?.country,
                  zipCode: userAccessBadge.organization?.primaryZipCode,
                  mbOrgUsage: Metrics.getMetabaseOrgUsageLink(
                    userAccessBadge.organization?.id,
                  ),
                  mbDistrictUsage: Metrics.getMetabaseDistrictOrgUsageLink(
                    userAccessBadge.organization?.parent?.id,
                  ),
                },
              }
            : undefined,
      });
      NewRelic()?.setCustomAttribute('email', userAccessBadge?.user.email || null);
      NewRelic()?.setCustomAttribute('uid', userAccessBadge?.user.id || null);
      NewRelic()?.setUserId(userAccessBadge?.user.id || null);
      NewRelic()?.setCustomAttribute('plan', plan);
      NewRelic()?.setCustomAttribute('subPlan', subPlan);
      NewRelic()?.setCustomAttribute(
        'curipodTrialReason',
        userAccessBadge.license.source ?? null,
      );
      NewRelic()?.setCustomAttribute(
        'organizationId',
        userAccessBadge.organization?.id ?? null,
      );
      NewRelic()?.setCustomAttribute(
        'organizationName',
        userAccessBadge.organization?.name ?? null,
      );
      NewRelic()?.setCustomAttribute(
        'districtId',
        userAccessBadge.organization?.parent?.id || null,
      );
      NewRelic()?.setCustomAttribute(
        'districtName',
        userAccessBadge.organization?.parent?.name || null,
      );
    }
  }, [updateIntercom, userAccessBadge]);

  useEffect(() => {
    // Unleash context
    if (userAccessBadge) {
      const plan = userAccessBadge.license.coarseLevel.toLowerCase();
      const subPlan = userAccessBadge.license.fineLevel.toLowerCase();
      updateContext({
        userId: userAccessBadge.user.email,
        properties: {
          email: userAccessBadge.user.email,
          role: userAccessBadge.role,
          tenantName: userAccessBadge.organization?.name || 'unknown',
          plan: plan,
          subPlan: subPlan,
          curipodTrialReason: userAccessBadge.license.source || 'unknown',
          districtId: userAccessBadge.organization?.parent?.id || 'unknown',
          districtName: userAccessBadge.organization?.parent?.name || 'unknown',
          state:
            userAccessBadge.organization?.country === 'US'
              ? userAccessBadge.organization?.region || 'unknown'
              : 'unknown',
          region: userAccessBadge.organization?.region || 'unknown',
          country: userAccessBadge.organization?.country ?? 'unknown',
          organisationId: userAccessBadge.organization?.id || 'unknown',
          organisationName: userAccessBadge.organization?.name || 'unknown',
        },
      });
    }
  }, [userAccessBadge, updateContext]);

  /**
   * Here we track route change so we know we have atleast the id of the user when track
   */
  useEffect(() => {
    // track initial page loads
    Metrics.getLogger().logEvent(
      //TODO: this is very event heavy
      'Route.Change',
      {
        pathname: window.location.pathname,
        url: router.pathname,
        search: window.location.search,
      },
      true,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Setup tracking for router.events at Nextjs
    const handleRouteChange = () => {
      Metrics.getLogger().logEvent(
        //TODO: this is very event heavy
        'Route.Change',
        {
          pathname: window.location.pathname,
          url: router.pathname,
          search: window.location.search,
        },
        true,
      );
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
}

export default useIdentifyMetrics;
